.dialogue-card {
  position: relative;
  border:"none";

  .dialogue-card__container {

    .dialogue-card__content {
      .dialogue-list__last-message {
        font:500 14px Roboto, sans-serif;
        color:#101010;
      }
    }

    .dialogue-card__footer {
      display: flex;
      justify-content: space-between;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.dialogue-card_helping {
  &::before {
    content:'';
    position: absolute;
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: red;
    top:calc(50% - 3px);
    left: 6px;
    // z-index: ;
    // transform: translateY(50%)
  }
}

.dialogue-card_selected {
  background-color: #0F6CA51F;
}

.dialogue-card_need-help {
  animation: 4s ease-in-out infinite alert !important;
}

@keyframes alert {
  0% {
    background-color: rgba(255, 65, 65, 0);
  }
  50% {
    background-color: rgb(255, 65, 65);
  }
  100% {
    background-color: rgba(255, 65, 65, 0);
  }
}
