@import '../../mixins/news-header-styles.scss';

.news-list-item__header {
  @include header-container-styles();

  .news-list-item__buttons {
    @include buttons-styles();

    .news-list-item__edit-icon {
      @include edit-icon-styles();
    }

    >:nth-child(n+1) {
      @include each-icon-styles()
    }
  }
}