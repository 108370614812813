.accounts {
  // width:100%;
  padding: 0 60px;

  .accounts__container {
    display: flex;
    flex-direction: column;
    padding-top: 1%;

    .new-operator-button__container {
      width: fit-content;
      align-self: end;
      margin-bottom: 1%;
    }
  }
  .is-deactivated {
    color:rgba(0, 0, 0, 0.3)
  }
}

