.gifts__container {
  margin-bottom: 10px;
  padding: 7px 0 7px;
  width: 100%;

  .gifts__title {
    font-size: 1rem;
    font-weight: 700;
  }
  .gifts__items {
    margin-left: 10px;
  }
}