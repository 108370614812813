.chat__footer {
  display: flex;
  justify-content:space-between;
  // height: 11.47%;
  flex-shrink: 0;
  flex-basis: 60px;
  align-items: center;
  margin:0 10px;
  padding: 10px 0;

  .chat__upload-button {
    align-self: end;
    padding-bottom: 5px;
  }
  .chat__message-input {
    flex-grow: 1;

    .chat__textarea {
      // background-color: black;
      min-height:36px;
      resize: none !important;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border:none;

      &:focus {
        border:none;
        box-shadow: none;
      }
    }
  }

  .chat__send {
    align-self: end;
    padding-bottom: 5px;
    margin-left: 3px;
    .chat__send-button {
      border:0;
      
      .send-button__icon {
        font-size: 24px;
        color: #4DA1FF;
      }
    }

  }

}

.chat__footer_not-allowed {
  justify-content:center;
  background-color:#F8F8F8;
}