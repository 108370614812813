.dialogue-drawer__header {
  display: flex;
  justify-content: space-between;
  .dialogue-drawer__buttons {
    display: flex;
    gap:7px;
    .dialogue-drawer__help-button {
      margin-right: 5%;
    }
  }
}