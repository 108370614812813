.document-sections {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  // gap:0 7px;
  grid-template-rows: repeat(4, 1fr);
  grid-auto-flow: column;
  // display: flex;
  // flex-direction: column;
  // flex-wrap: wrap;
  width: 100%;
  height:100%;
}
.assistant-sections {
 display: flex;
 flex-direction: column;
}