
.company-finance {
  padding: 1.5rem;
  .company-finance__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .company-finance__title {
      
    }
    .company-finance__balance-info {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
}