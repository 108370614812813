.loading {
  width:100%;
  height:100%;
  // margin-top: 50px;
  display:flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  .loading__text {
    margin-left: 7px;
  }
}