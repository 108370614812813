.chat__applicant-name {
  display: flex;
  gap: 5px;
  align-items: center;
  .applicant-name__name{
    overflow-x: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .applicant-name__tools {
    flex-shrink: 0;
  }
}