@import '../../mixins/news-header-styles.scss';


.news-editor__header {
  @include header-container-styles();


  .news-editor__buttons {
    @include buttons-styles();

    .news-editor__edit-icon {
      @include edit-icon-styles();
    }

    >:nth-child(n+1) {
      @include each-icon-styles();
    }
  }
}