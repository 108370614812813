.aside {
  position: fixed !important;
  left:0 !important;
  top:64px !important;
  bottom:0 !important;
  z-index: 100 !important;
  background-color: #182A67 !important;
  .aside__menu {
    background-color: transparent;
    color: white;
    margin-top: 50px;
  }
}

