.news__picture-preview-container {
  width:100%;
  height: 318px;
  background-color: #d8d8d8;
  .news__picture-preview {
    display: block;
    width: 100%;
    height: 100%;
    object-fit:cover;
  }
}