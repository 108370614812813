.application-workpage__left {
  height: 100%;
  overflow-y: auto;
}
.application-workpage__right {
  position: relative;
  height: 100%;
  overflow-y: auto;

  .chat-section {
    height: 72%;
    margin-bottom: auto;
  }
  .sections-divider {
    height:2%;
  }

}

