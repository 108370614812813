.new-news-form__container {
  width: 100% ;
  box-shadow: 0 0 5px 0px black;
  border-radius: 8px;
  padding: 2%;
  margin-bottom: 2%;


  .new-news-form__text-container {
    margin-bottom: 2%;
  }
  .new-news-form__footer {
    display: flex;
    justify-content: space-between;
    .new-news-form__actions {
      display: flex;
      width: 70%;
      .new-news-form__files-download-container {
        width:100%;
      }
    }
  }
}

