.documents-history__item {
  display: grid;
  // justify-content: space-between;
  // grid-template-columns: 3fr 1fr 3fr 1fr 1fr 1fr;
  grid-template-columns: 25% 10% 25% 10% 10% 10%;
  gap:2%;
  .documents-history__doc-name {
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    
  }
}