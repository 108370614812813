.additional-services__list-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  // .additional-services__price {
  //   // .additional-services__applicants-num {
  //   //   position: absolute;
  //   //   transform: translate(-100%, 80%);
  //   //   font-size: 0.8rem;
  //   //   color:rgba(100, 100, 100, 0.712)
  //   // }
  // }
  
}