.chat__applicant-phone {
  display: flex;
  align-items: center;
  gap:5px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
  // align-self: end;
  min-width: fit-content;
  // margin-left:2%;

  .client-phone-edition-form {
    display: flex;
  }
}
.chat__applicant-phone_is-edit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}