.column-selector__container {
  min-width:160px;
  .column-selector__selector {
    width:100%;
    .column-selector__placeholder {
      color:#afafaf;
    }
  }
}
.column-selector__divider {
  margin: 4px 0;
}
.column-selector__dropdown-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0 8px 4px;
}