.section__container {
  display: flex;
  justify-content: space-between;
  // width:50%;
  margin-bottom: 1%;
  flex-basis: 50%;
  &:nth-child(n) {
    margin-right: 10px;
  }

  .section__header {
    width:100%;
  }

  .section__docs-list {
    margin-left: 15px;
  }

}
.assistant-section {
  width: 100%;
}