.team-chat__chat-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 0.349);
  border-radius: 10px;

  .team-chat__messages-list {
    flex-grow: 1;
    overflow: auto;
  }
  .team-chat__leave-message-container {
    display: flex;
    padding: 5px;
    align-items: center;

    .team-chat__textarea-container {
      padding:5px;
      flex-grow: 1;
      .team-chat__textarea {
        min-height:36px;
        resize: none !important;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border:none;

        &:focus {
          border:none;
          box-shadow: none;
        }
      }
    }
    .team-chat__send-button-container {
      .team-chat__send-button {
        border:none;
        color: #4096ff;
        .team-chat__send-button-icon {
          font-size: 24px;
        }
      }
    }
  }
}