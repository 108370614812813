.company-finance__recon-statement {
  width: 70%;
  margin: 1.5rem 0;
  border: 1px solid black;
  background-color: #fff;
}
.recon-statement {

  .recon-statement__before-table, .recon-statement__after-table {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 0.5rem;
    .recon-statement__balance-addon {
      display: flex;
      justify-content: flex-end;
    }
  }
  .recon-statement__before-table {
    .recon-statement__tools {
      display: flex;
      justify-content: space-between;
      .recon-statement__period-filter {
        .period-filter__title {
          font-weight: 700;
        }
      }

    }
    .recon-statement__balance-before {
      display: flex;
      justify-content: flex-end;
    }
  }
  .recon-statement__after-table {
    .recon-statemen__balance-after {
      display: flex;
      justify-content: flex-end;
    }
  }
}