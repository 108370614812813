.team-app-content__container {
  height: 80vh;
  display: flex;
  flex-direction: column;

  .team-app-content__content {
    flex-grow: 1;
    display: flex;
    overflow-y: hidden;
  }
}