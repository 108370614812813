.company-info {
  display: flex;
  flex-direction: column;
  // padding: 1.5rem;
  font-size: 1rem;

  .company-info__item {
    // display: flex;
    margin-bottom: 1rem;
    .company-info__label {
      font-weight: 700;
    }
  }
}