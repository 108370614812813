@import '../../constants.scss';

.section__title {
  position: relative;
  width:100%;
  .section__title-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .section__title-text {
      overflow-x: hidden;
      text-overflow: ellipsis;
      padding:0px 3px;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.144);
      border-radius: 5px;
      cursor:pointer;
      transition: all 0.3s;

      &:active {
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.24);
      }
    }
    .section__additional {
      display: flex;
      .section__assistant-name {
        font-size: 0.8rem;
        // color:rgb(99, 122, 226);
        color:$assistantName;
        overflow: hidden;
        text-overflow: ellipsis;
      }

    }

  }
  .section__spinner-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    transform:translate(150%,0);
  }
}