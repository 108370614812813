.service-message__container {
  padding:1% 2%;
  .service-message__inner {
    text-align: center;
    color:rgb(146, 146, 146);
    .service-message__author-name,
    .service-message__assistant-name {
      font-weight: 700;
    }
  }
}