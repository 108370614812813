.gift__container {
  display: flex;
  width: 100%;
  white-space: pre-wrap;

  .gift-num {
    font-weight: bold;
  }
  .gift-name {
    padding-right: 5px;
  }
}