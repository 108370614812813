.details-prices__list-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .details-prices__detail-name {
    .details-prices__package-name {
      position: absolute;
      transform: translate(-50%, 85%);
      font-size: 0.8rem;
      font-weight: 700;
      color:#0F6CA5;
    }
  }

  .details-prices__total-price-name {
    font-weight: 700;
  }

  .details-prices__total-price-value {
    font-size:1.3rem;
    color: #0F6CA5;
  }
}

.details-prices__change-price-container {
  display: flex;
  justify-content: flex-end;
}