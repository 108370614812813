.employee__container {
  display: flex;
  align-items: center;
  .employee__role-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  .employee__name {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .employee__not-found {
    color: rgba(0, 0, 0, 0.3);
    text-overflow: ellipsis;
    overflow: hidden;
  }
}