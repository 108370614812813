@import '../../constants.scss';

.section-content__title {
  font-weight: 700;
  font-size: 1.45rem;
  text-align: center;
  margin-bottom: 5px;
  
  .section-content__assistant-name {
    color: $assistantName;
  }
  .section-content__status-select {

  }
}
