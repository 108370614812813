.file-list__list-item {
  padding: 0 10px;
  transition: background-color 0.3s;
  border-radius:10px;

  &:hover {
    background-color: #00000009;
    box-shadow: inset 0 0 7px 0 rgba(0, 0, 0, 0.075);
  }
  .file-list__content {
    display: flex;
    justify-content: space-between;

    
    .file-list__delete-icon-container {
      margin-left: 3px;
      cursor: pointer;

      &:active {
        transform:scale(0.9)
      }
      .file-list__delete-icon {
        // color: #8b8b8b;
        color: rgb(255, 91, 91);
      }
    }
  }

}