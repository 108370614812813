.chat-upload-attachment__container {
  display:flex;
  margin-bottom: 5px;

  .chat-upload-attachment__name {
    font: 700 16px Inter, sans-serif;
    margin-bottom:6px;
  }
  
  .chat-upload-attachment__size {
    font: italic 14px sans-serif;
    color: rgba(109, 107, 107, 0.575);
  }
}
