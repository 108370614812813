.dialogue-list__card-title {
  display: grid;
  grid-template-columns: 1fr 1fr 136px 15%;
  column-gap:2%;
  align-items: center;

  .card-title__client-name {
    width:75%;
    overflow:hidden;
    text-overflow: ellipsis;
    font: 700 16px Roboto, sans-serif;
  }

  .card-title__service-info {
    align-self: center;
    // width:25%;
    display:flex;
    align-items: center;
    margin-left:auto;

    .card-title__unread {

      .unread__indicator {
        background-image:linear-gradient(#36CE00, #00D8A4);
      }
    }

    .card-title__last-message-info {
      margin-left:5px;
      color:#727272;
      font: 500 11px Roboto, sans-serif;
      .card-title__last-message-date {
        font-size: 10px;
      }
      .card-title__last-message-time {
        text-align: center;
      }
    }
  }
}