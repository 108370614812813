.team-files__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width:50%;

  .team-files__title {
    position: relative;
    display: flex;
    gap: 0 10px;
    margin-bottom: 1%;
    .team-files__deleting-spinner {
      position: absolute;
      top:0;
      right: 0;
      transform: translateX( 150%);
    }
  }

  .team-files__files {
    display: flex;
    flex-direction: column;
    width: 100%;

    .team-files__file-list {
      width:70%;
    }
  }

}