.head {
  // по умолчанию height:64px у компонента Head
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1024;
  width: 100%;
  padding: 0;
  // background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.103);

  .logo__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 100%;

    .logo {
      height: 80%;
      width: fit-content; // если хотим, чтобы кликабельной была вся область, выделенная под логотип в 200px, то width: 100% , или просто удалить свойство width

      &:hover {
        cursor: pointer;
      }
    }
  }

  .head__content {
    width: calc(100% - 200px);
    display: flex;
    justify-content: space-around;
    align-items: center;
    .head__stub {
      width: 47%;
    }

    .apps-search-bar__container {
      display: flex;
      align-items: center;
      width: 47%;
    }
    .top-navigation {
      display: flex;
      align-items: center;

      .logout {
        display: flex;
        flex-direction: column;
        line-height: 1.5;

        .logout__button {
          align-self: center;
        }
        .logout__user-name {
          text-align: center;
          .logout__name {
            font-style:italic;
          }
        }
      }
    }
  }
}
