.team-upload__container {
  .team-upload__icon-container {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.253);
    border-radius: 6px;
    padding: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;

    &:hover {
      background-color: #0000000a;
    }

    &:active {
      background-color: #00000021;
      box-shadow:inset 0 0 1px 0 rgba(0, 0, 0, 0.253);

    }

    .team-upload__upload-icon {
      &:active {
        transform: scale(0.9);
      }
    }
  }

  .upload-not-allowed {
    pointer-events: none;
    user-select: none;
  }
}