.aplication-info-card__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .aplication-info-card__finish-issue-container {
    align-self:end;
  }
 .aplication-info-card__assignedTo {
  .ant-select-disabled {
    .ant-select-selector {
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
 }

  // &:last-child {
  //   margin-right:80px;
  // }
}