@mixin header-container-styles() {
  display: flex;
  justify-content: space-between;
  padding: 2px 10px 0;
}

@mixin buttons-styles() {
  display: flex;
  align-items: center;
}

@mixin edit-icon-styles() {
  font-size: 22px;
  color: #08c;
}

@mixin each-icon-styles() {
  margin-left:10px;
}

