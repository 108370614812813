.file-list__file-name {
  overflow-x: hidden;
  text-overflow: ellipsis;
  .file-list__link {
    &:link, &:visited {
      color:rgb(75, 75, 255);
    }
    &:active {
      color:rgb(33, 33, 214);
    }
  }
}