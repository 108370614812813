.message__container {
  margin-bottom: 2px;
  padding:0 5px;

  .message__content {
    padding:7px 12px;
    width:fit-content;
    max-width:60% ;
    text-align:start;
    border-radius:14px;
    .message__title {
      display: flex;
      justify-content: space-between;
      .message__senderName {
        font-style: italic;
        color:#00000069;
        transform: translateX(-7px)
      }
      .message-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 5px;
        .message-menu__dot {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: rgb(124, 124, 124);
          &:not(:last-child) {
            margin-bottom: 2px;
          }
        }
      }
    }

    .message__text{
      overflow-wrap: break-word;
      white-space: pre-wrap;
    }

    .message__status {
      display:flex;
      justify-content: flex-end;
      gap:5px;

      .message__created-time{
        font-size: 12px;
      }
    }
  }
  
  .message__content_send-failed {
    box-shadow: 0 0 0 1px rgb(224, 66, 66);
    background-color: rgb(241, 215, 215) !important
  }

  .message__content.operator .message__created-time {
    color: #fff;
  }

  .message__content.applicant .message__created-time {
    color: #011627;
  }

  .operator {
    margin-left: auto;
    background-color: #D9D9D9;
  }

  .applicant {
    background-color: #fff;
    margin-right: auto;
  }
}
