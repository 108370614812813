.applications {
  color:#333;
  padding: 1% 0;
  .applications__title {
    text-align: center;
    padding:0 3%;
    margin: 1.3rem 0;
    color:#333;
    .title__client-uid {
      display: block;
      font-size: 1rem;
    }
  }
}
