@mixin newsStyles() {
  p {
    margin-top:0.2em;
    margin-bottom: 0.8em;
  }
  ul, ol {
    margin-left: 2.666em;
    margin-bottom: 0.8em;

  }
  ul {
    list-style:disc;
    ul {
      list-style:circle;
      ul {
        list-style: square;
      }
    }
  }
  ol {
    list-style: decimal;
    ol {
      list-style: lower-latin;
      ol {
        list-style: lower-roman;
        ol {
          list-style: upper-latin;
          ol {
            list-style: upper-roman;
          }
        }
      }
    }
  }
  a {
    color: rgb(85, 85, 255);
  }
  h1, h2, h3 {
    margin-top:0.8em;
    margin-bottom: 0.2em;
  }
  tr {
    border: 1px solid black;
    // background-color: red;
  }
  .ck-content  {
    min-height:150px;
  }
  table {
    border: 1px double #b3b3b3;
    border-collapse: collapse;
    border-spacing: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    tr {
      border: 1px solid black;
    }
    td {
      border: 1px solid #bfbfbf;
      min-width: 2em;
      padding: .4em;
      overflow-wrap: break-word;
    }
  }
}