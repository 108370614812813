.news-list-item__news-info {
  display: flex;
  gap:3%;
  font-size: 0.8rem;
  color:rgb(160, 158, 158);

  .news-list-item__date {
    flex-shrink: 0;
  }

  .news-list-item__author-container {
    flex-shrink: 0;
    .news-list-item__author-name {
      font-style: italic;
    }
  }
}