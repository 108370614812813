.picture-delete-button__container {
  position:absolute;
  padding:5px;
  right:10px;
  top:10px;
  border-radius: 10px;
  transition: all 0.3s;
  cursor: pointer;
  // height: fit-content;
  &:hover {
    background-color: rgba(255, 255, 255, 0.164);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.418), 0 0 5px 0 rgba(0, 0, 0, 0.596);
  }
  &:active {
    box-shadow: inset 0 0 4px 0 black;
  }
  .picture-delete-button__icon {
    font-size:20px;
    color:rgb(116, 116, 116);
    display: block;
    &:active {
      transform:scale(0.8)
    }
  }
}
