.push-history-item__container {
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.178);
  margin-bottom: 10px;
  border-radius: 5px;
  padding:0.7rem 10px 5px;
  .push-history-item__content {
    margin-left: 7px;
    overflow: hidden;
    .push-history-item__title {
      white-space: pre-wrap;
      overflow-wrap: break-word;
    }
    .push-history-item__text {
      white-space: pre-wrap;
      overflow-wrap: break-word;
    }
  }
  .push-history-item__metadata {
    position: absolute;
    top:0;
    right: 0;
    margin-right: 7px;
    font-size:12px;
    color: rgb(182, 182, 182);
  }
}