.operator-deactivate {
  width:50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:0 auto;

  .operator-deactivate__title{
    text-align: center;
    margin-bottom: 4%;

    .operator-deactivate__operator-to-be-deactivated {
      display: block;
      font-weight: 600;
    }
  }
  .operator-deactivate__buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10%;
  }
}