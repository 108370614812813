.person-message__container {
  padding:1% 2%;

  .person-message__inner {
    max-width:60%;
    box-shadow: 1px 1px 4px 0 rgba(155, 97, 97, 0.438);
    border-radius: 5px;
    padding:5px 10px;
    margin-left: auto;
    background-color: hsl(204, 100%, 92%);


    .person-message__metadata {
      transform:translateX(-5px);
      display: flex;
      color: rgb(146, 146, 146);
    }
    .person-message__text {
      white-space: pre-wrap;
      overflow-wrap: break-word;
    }
  }
  .assistant {
    margin-left: 0;
    background-color: #fff;
  }
}