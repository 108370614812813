.push-creator-page__container {
  display: flex;
  justify-content: space-between;
  max-height:calc(100vh - 64px);
  height: 100%;
  width: 100%;
  padding: 4% 4% 0;

  .push-creator__container {
    width:40%;
  }

  .push-history__container {
    width:40%;
    height:100%;
  }
}