.documents-section {
  height:26%;
  // &:hover {
  //   position: relative;
  //   height: 700px;
  //   top:0;
  //   left:0;
  //   // transform:translateY(-translateY);
  //   z-index:1;
  // }
  .uploaded-documents {
    height:100%;
    overflow:auto;
    padding: 10px 5px;
    background-color: #fff;
    border-radius: 10px;

    .uploaded-documents__upload-section {
      width: 100%;

      a:hover, a:active, a:visited, a:link {
        color:#0000FF;
      }
    }
  }
}
.assistant {
  min-height: 26%;
  height: auto;
}

