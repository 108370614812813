.corp-text {
  width: 100%;
  font-size: 0.8rem;

  .corp-text__tag {
    width:100%;
    white-space: nowrap;
    overflow:  hidden;
    text-overflow: ellipsis;
  }
}