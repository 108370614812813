.error-list {
  color: red;
  font-size: 0.7rem;
  opacity: 1;
  animation: appear 6s ease-in-out;
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1
  }
  90% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}