.assigned-sections__container {
  .assigned-sections__before-table {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .assigned-sections__after-table {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}