* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: #F5F5F5;
}

ul, ol {
  list-style: none;
}

a:active, a:hover, a:visited {
  color: inherit;
}

.interactive-icons {
  transition: transform 0.1s;

  &:hover {
    cursor:pointer;
  }

  &:active {
    transform:scale(0.85);
  }
}

.interactive-icons-disabled {
  transition: none;

  &:hover {
    cursor:not-allowed;
  }

  &:active {
    transform:scale(0.85);
  }
}
// Scroll bar
::-webkit-scrollbar {
  width:6px;
}

::-webkit-scrollbar-track {
  display:none;
  width:1px;
  background-color: #b8d5ff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  width:6px;
  background-color: #1677ff;
  border-radius: 10px;
}

.is-deleted {
  opacity: 0.3;
}
.is-new {
  // background-color: #ebf2f8;
  // background-color: #56DA27;
  // background-color: #d3e7cc88;
  background-color: #89d37088;

}
.disabled {
  opacity: 0.3;
  pointer-events: none;
  user-select: none;
}
.not-display {
  display: none;
  opacity: 0;
}
.error-text-style {
  color: rgb(255, 108, 108);
}
.postfix-applicants-num {
  position: absolute;
  transform: translate(-100%, 85%);
  font-size: 0.77rem;
  color:rgba(100, 100, 100, 0.712)
}
.table-group {
  background-color: #fce4cd;
}
.time-column {
  // font-size: 0.75rem;
}