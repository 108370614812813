.preview {
  position: relative;

  .preview__object {
    width: 150px;
    height: 200px;
  }

  .preview__cover {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 200px;
    background-color: rgba(0,0,0,0);
    transition:background-color 0.2s;

    &:hover {
      background-color: rgba(0,0,0,0.3);
      .preview__cover-increaser-img {
        color:rgba(0,0,0,0.3);
      }
    }

    .preview__cover-increaser-img {
      font-size:32px;
      color:rgba(0,0,0,0);
      transition:color 0.2s;
      transition:transform 0.2s;

      &:active {
        transform:scale(0.9)
      }
    }
  }
}