.mask {
  position: absolute;
  width:100%;
  height: 100%;
  background-color: #00000077;
  z-index: 96;
  animation: show-mask 0.3s ease-in forwards;
  visibility: hidden;
}

.mask_hidden {
  animation: hide-mask 0.3s ease-in;
}

@keyframes show-mask {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes hide-mask {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}