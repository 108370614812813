.chat__container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  border-radius: 10px;
  background-color: white;
  width: 100%;
  height: 100%;
  font: 400 14px Jost, sans-serif;

  .chat__info {
    display: flex;
    // justify-content: space-between;
    background-color: #182A67;
    overflow: hidden;
    flex: 0 0 100px;
    padding: 7px 12px;
    color: white;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.125);
    font-size: 16px;

    .chat__common-info {
      // display: flex;
      flex-direction: column;
      flex: 0 0 35%;

      .chat__applicant-id {
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 11px;
      }

      .chat__operator-info {
        display: flex;
        align-items: center;
        font-size: 11px;
      }

      .chat__active-status {
        display: flex;
        align-items: center;
      }
    }

    .chat__main-app {
      flex: 0 0 45%;
      font-size: 11px;
      display: flex;
      flex-direction: column;
      // width: 200px;
      // min-width: 200px;

      .main-app__title {
        text-align: center;
        font-size: 13px;
      }
      .main-app__content {
        display: flex;
        flex-direction: column;
        align-items:center;
        flex-wrap: wrap;
        overflow-y: hidden;
      }
    }

    .chat__status {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      flex: 0 0 20%;
      overflow: hidden; text-overflow: ellipsis;
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder,
    .ant-select-arrow {
      color: white;
    }

    .ant-select-disabled {
      .ant-select-selection-item,
      .ant-select-selection-placeholder,
      .ant-select-arrow {
        color: rgba(255, 255, 255, 0.47);
      }
    }
  }

  .chat__info__account-deleted {
    background-color: #182a6748;

  }

  .chat__messages {
    position: relative; // нужно для считывания offsetTop дочерних элементов (сообщений)
    display: flex;
    flex-grow: 0;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;

    .unread-notification {
      margin-bottom: 5px;
      padding: 5px;
      font-size: 16px;
      font-weight: 500;
      color: #1677ff;
      text-align: center;
    }

    .unread-messages__container {
      background-color: rgba(233, 250, 255, 0.479);
    }

    .invisible-container {
     flex-grow: 1;
    }
  }
}
