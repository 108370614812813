.push-history__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  .push-history__title {
    margin-bottom: 0.5rem;
  }
  .push-history__list-container {
    flex-grow: 0;
    padding:7px;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.103);
    border-radius: 7px;
    height:95%;
    padding: 10px 7px 0;
    overflow-y: auto;
  }
}
